import React, { useMemo } from 'react';
import { $Button } from './_styles';
import { baseButtonThemeColors } from './_themes';
/**
 * Базовая кнопка
 * @param {ReactNode} children - текст кнопки
 * @param {boolean} disabled  - блокирует доступ и изменение кнопки
 * @param {string} buttonType  - определяет тип кнопки (большая/маленькая)
 * @param {string} theme  - определяет тему кнопки
 * @param {string} className  - внешний класс
 * @param {string} buttonClassName  -  класс для кнопки
 * @param {string} link - ссылка (внутренняя или внешняя)
 */
export const BaseButton = React.memo(({ children, onClick, style, disabled, buttonType = 'primary', theme = 'blue', className = '', }) => {
    const themeData = useMemo(() => {
        return baseButtonThemeColors[theme];
    }, [theme]);
    return (React.createElement($Button, { className: className, buttonType: buttonType, themeData: themeData, onClick: onClick, style: style, disabled: disabled }, children));
});
