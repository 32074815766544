import styled, { css } from 'styled-components';
import { Game_P1, Landing_H2, screenSizes, baseTransition } from '../../../../styles';
export const baseButtonStyles = css `
  position: relative;
  border: none;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  ${baseTransition};

  &:disabled {
    cursor: default;
  }

  ${({ buttonType }) => {
    switch (buttonType) {
        case 'primary':
            return css `
          min-width: 222px;
          ${Landing_H2()};
          padding: 19px 10px 18px;
          line-height: 18px;
        `;
        default:
            return css `
          min-height: 32px;
          min-width: 102px;
          ${Game_P1()}
          padding: 3px 10px 5px;

          @media ${screenSizes.MOBILE} {
            padding: 3px 4px 5px;
          }
        `;
    }
}}

  ${({ themeData }) => {
    return css `
      color: ${themeData.base.textColor};
      background: ${themeData.base.backgroundColor};
      border: ${themeData.base.border};
      opacity: ${themeData.base.opacity};

      &:hover {
        color: ${themeData.hover.textColor};
        background: ${themeData.hover.backgroundColor};
        border: ${themeData.hover.border};
        opacity: ${themeData.hover.opacity};
      }

      &:active {
        color: ${themeData.active.textColor};
        background: ${themeData.active.backgroundColor};
        border: ${themeData.active.border};
        opacity: ${themeData.active.opacity};
      }

      &:disabled {
        color: ${themeData.disabled.textColor};
        background: ${themeData.disabled.backgroundColor};
        border: ${themeData.disabled.border};
        opacity: ${themeData.disabled.opacity};
      }
    `;
}}
`;
export const $Button = styled.button `
  ${baseButtonStyles}
`;
export const $LinkButton = styled.a `
  display: block;
  text-decoration: none;
`;
