import React, { useState, useMemo } from 'react';
import { $InputWrapper, $Input, $Placeholder, $ErrorText, $Icon, } from './_styles';
import { baseInputThemeStyles } from './_themes';
import { areObjectsEqual } from '../../../../helpers/_equality';
import { useClassNames } from '../../../../hooks/_useClassNames.hook';
import { capitalizeString } from '../../../../helpers/_common';
function arePropsEqual(prevProps, nextProps) {
    return areObjectsEqual(prevProps, nextProps);
}
// TODO: рефактор тем
/**
 * Базовое поле ввода.
 * @param {string} value - текущее значение поля ввода
 * @param {object} createRef - создает ссылку на поле ввода (ref)
 * @param {string} placeholder- динамический плейсхолдер типа material
 * @param {string} className - внешний класс
 * @param {function} onChange - обработчик события изменения значения поля ввода
 * @param {function} onEnterPress - обработчик события нажатия Enter
 * @param {string} theme - тема инпута
 * @param {string} errorText- текст ошибки
 * @param {boolean} showError  - выводить ли ошибку
 * @param {string} errorTheme - вариант отображения ошибки
 * @param {Element} icon - компонент с иконкой
 * @param {string} name - имя поля ввода
 * @param {boolean} disabled - блокирует доступ и изменение поля ввода
 * @param {string} type - тип поля ввода
 * @param {boolean} readOnly - доступно ли изменение поля вода пользователю
 * @param {string} min - минимальное значение для поля ввода
 * @param {string} max - максимальное значение для поля ввода
 */
export const BaseInput = React.memo(({ value, createRef, placeholder = '', className = '', onChange, theme = 'primary', errorText = null, showError = false, errorTheme = 'full', icon, name = '', disabled = false, type = '', readOnly = false, min = '', max = '', maxLength, onEnterPress,
// labelText,
 }) => {
    const [localValue, setLocalValue] = useState('');
    const themeData = useMemo(() => {
        return baseInputThemeStyles[theme];
    }, [theme]);
    const labelClasses = useClassNames({
        [theme]: !!theme,
        ['error']: showError || !!errorText,
        [errorTheme]: (showError || !!errorText) && !!errorTheme,
        ['input_which_icon']: !!icon,
        [className]: true,
    });
    const spanClasses = useClassNames({
        ['active']: 
        // Boolean(labelText) ||
        type === 'date' ||
            (value !== undefined &&
                value !== null &&
                value.toString().length !== 0) ||
            localValue.length !== 0,
    });
    function changeHandler(event) {
        if (typeof onChange === 'function') {
            return onChange(event);
        }
        setLocalValue(event.target.value);
    }
    const handleEnterPress = (e) => {
        if (e.key === 'Enter' && typeof onEnterPress === 'function') {
            onEnterPress();
        }
    };
    return (React.createElement($InputWrapper, { className: labelClasses, themeData: themeData },
        icon && React.createElement($Icon, null, icon),
        React.createElement($Input, { value: value, ref: createRef, placeholder: "\u00A0", onChange: changeHandler, name: name, disabled: disabled, type: type, readOnly: readOnly, min: min, max: max, maxLength: maxLength, onKeyPress: handleEnterPress }),
        React.createElement($Placeholder, { className: spanClasses },
            React.createElement("span", { className: 'placeholder_text' }, capitalizeString(placeholder)),
            !!errorText && React.createElement($ErrorText, null, errorText))));
}, arePropsEqual);
