import { css } from 'styled-components';
const getCssPropertyIfThemePropertyExists = (cssProperty, themeProperty) => {
    return themeProperty
        ? css `
        ${cssProperty}: ${themeProperty};
      `
        : '';
};
export const getInputStyles = (themeData) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const inputColor = getCssPropertyIfThemePropertyExists('color', (_a = themeData.base) === null || _a === void 0 ? void 0 : _a.color);
    const fontSize = getCssPropertyIfThemePropertyExists('font-size', (_b = themeData.base) === null || _b === void 0 ? void 0 : _b.fontSize);
    const fontWeight = getCssPropertyIfThemePropertyExists('font-weight', (_c = themeData.base) === null || _c === void 0 ? void 0 : _c.fontWeight);
    const lineHeight = getCssPropertyIfThemePropertyExists('line-height', (_d = themeData.base) === null || _d === void 0 ? void 0 : _d.lineHeight);
    const placeholderText = getCssPropertyIfThemePropertyExists('font-size', (_e = themeData.placeholder) === null || _e === void 0 ? void 0 : _e.fontSize);
    const padding = getCssPropertyIfThemePropertyExists('padding', (_f = themeData.base) === null || _f === void 0 ? void 0 : _f.padding);
    const leftPadding = getCssPropertyIfThemePropertyExists('padding-left', (_g = themeData.icon) === null || _g === void 0 ? void 0 : _g.leftPadding);
    return {
        inputStyles: css `
      ${inputColor}
      ${fontSize}
      ${fontWeight}
      ${lineHeight}
      ${placeholderText}
      ${padding}
    `,
        inputWithIconStyles: css `
      ${leftPadding};
    `,
    };
};
export const getPlaceholderStyles = (themeData) => {
    var _a, _b, _c, _d, _e;
    const placeholderText = getCssPropertyIfThemePropertyExists('font-size', (_a = themeData.placeholder) === null || _a === void 0 ? void 0 : _a.fontSize);
    const placeholderLineHeight = getCssPropertyIfThemePropertyExists('line-height', (_b = themeData.placeholder) === null || _b === void 0 ? void 0 : _b.lineHeight);
    const placeholderTransform = getCssPropertyIfThemePropertyExists('transform', (_c = themeData.placeholder) === null || _c === void 0 ? void 0 : _c.transform);
    const placeholderFontWeight = getCssPropertyIfThemePropertyExists('font-weight', (_d = themeData.placeholder) === null || _d === void 0 ? void 0 : _d.fontWeight);
    const placeholderLeft = getCssPropertyIfThemePropertyExists('left', (_e = themeData.icon) === null || _e === void 0 ? void 0 : _e.leftPadding);
    return {
        placeholderStyles: css `
      ${placeholderText}
      ${placeholderLineHeight}
      ${placeholderTransform}
      ${placeholderFontWeight}
    `,
        placeholderWithIconStyles: css `
      ${placeholderLeft}
    `,
    };
};
export const getWrapperStyles = (themeData) => {
    var _a;
    const minHeight = getCssPropertyIfThemePropertyExists('min-height', (_a = themeData.base) === null || _a === void 0 ? void 0 : _a.minHeight);
    return css `
    ${minHeight}
  `;
};
export const getIconStyles = (themeData) => {
    var _a, _b, _c, _d;
    const width = getCssPropertyIfThemePropertyExists('width', (_a = themeData.icon) === null || _a === void 0 ? void 0 : _a.width);
    const height = getCssPropertyIfThemePropertyExists('height', (_b = themeData.icon) === null || _b === void 0 ? void 0 : _b.height);
    const top = getCssPropertyIfThemePropertyExists('top', (_c = themeData.icon) === null || _c === void 0 ? void 0 : _c.top);
    const left = getCssPropertyIfThemePropertyExists('left', (_d = themeData.icon) === null || _d === void 0 ? void 0 : _d.left);
    return css `
    ${width}
    ${height}
    ${top}
    ${left}
  `;
};
