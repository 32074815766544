import { textColors, transparentColor } from '../../../../styles';
export const baseInputThemeStyles = {
    primary: {
        input: {
            borderBottom: `1px solid rgba(255, 255, 255, 0.5)`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            color: textColors['white'],
            transform: `translateY(-3px)`,
            lineHeight: `20px`,
        },
        placeholder: {
            color: `rgba(255, 255, 255, 0.5)`,
            whiteSpace: `nowrap`,
        },
        active: {
            transform: `translateY(-3px)`,
            color: textColors['white'],
            lineHeight: `20px`,
        },
        error: {
            color: textColors['red'],
        },
    },
    primary_white: {
        base: {
            lineHeight: `25px`,
        },
        input: {
            borderBottom: `1px solid ${transparentColor(textColors['white'], 0.2)}`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            color: textColors['white'],
            transform: `translateY(-3px)`,
            lineHeight: `20px`,
        },
        placeholder: {
            color: `${textColors['white']}`,
            whiteSpace: `nowrap`,
        },
        active: {
            transform: `translateY(-3px)`,
            color: textColors['white'],
            lineHeight: `20px`,
        },
        error: {
            color: textColors['red'],
        },
    },
    secondary: {
        base: {
            overflow: `hidden`,
        },
        input: {
            borderBottom: `1px solid rgba(255, 255, 255, 0.5)`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            color: textColors['black'],
            transform: `translateY(-3px)`,
            lineHeight: `20px`,
        },
        disabled: {
            borderBottom: `1px solid rgba(0, 0, 0, 0.5)`,
        },
        placeholder: {
            color: `rgba(255, 255, 255, 0.5)`,
            whiteSpace: `nowrap`,
        },
        active: {
            transform: `translateY(-3px)`,
            color: textColors['black'],
            lineHeight: `20px`,
        },
        error: {
            color: textColors['red'],
        },
    },
    login: {
        input: {
            borderBottom: `1px solid rgba(255, 255, 255, 0.5)`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            color: textColors['white'],
            transform: `translateY(-10px) scale(0.7)`,
            lineHeight: `20px`,
        },
        placeholder: {
            color: `rgba(255, 255, 255, 0.5)`,
        },
        error: {
            color: textColors['orange'],
        },
        active: {
            transform: `translateY(-10px)`,
            color: textColors['white'],
            lineHeight: `20px`,
        },
    },
    not_label: {
        input: {
            borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            display: 'none',
        },
        placeholder: {
            color: 'rgba(255, 255, 255, 0.5)',
        },
        active: {
            display: 'none',
        },
    },
    not_label_dark: {
        input: {
            borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            display: 'none',
        },
        placeholder: {
            color: 'rgba(255, 255, 255, 0.5)',
        },
        error: {
            color: textColors['orange'],
        },
        active: {
            display: 'none',
        },
    },
    inverted: {
        base: {
            fontSize: `15px`,
            lineHeight: `17px`,
            color: textColors['black'],
        },
        input: {
            borderBottom: `1px solid ${textColors['black']}`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['black']}`,
            color: textColors['black'],
            transform: `translateY(-3px)`,
            lineHeight: `20px`,
        },
        placeholder: {
            fontSize: `15px`,
            lineHeight: `17px`,
            transform: `translateY(24px)`,
            fontWeight: `300`,
            color: textColors['black'],
            whiteSpace: `nowrap`,
        },
        active: {
            transform: `translateY(-3px)`,
            color: textColors['black'],
            lineHeight: `20px`,
        },
        error: {
            color: textColors['red'],
        },
    },
    primary_new_design: {
        base: {
            padding: `0 0 6px 0`,
            fontWeight: `300`,
            lineHeight: `25px`,
        },
        placeholder: {
            transform: `translateY(15px)`,
            color: `rgba(255, 255, 255, 0.4)`,
            fontWeight: `300`,
            whiteSpace: `nowrap`,
        },
        input: {
            borderBottom: `1px solid rgba(255, 255, 255, 0.2)`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            color: textColors['white'],
            transform: `translateY(-3px)`,
            lineHeight: `20px`,
        },
        active: {
            transform: `translateY(-3px)`,
            color: textColors['white'],
            lineHeight: `20px`,
        },
        error: {
            color: textColors['red'],
        },
    },
    small: {
        base: {
            minHeight: `35px`,
            padding: `0 0 2px 0`,
            fontSize: `18px`,
            lineHeight: `24px`,
            fontWeight: `300`,
        },
        input: {
            borderBottom: `1px solid rgba(255, 255, 255, 0.2)`,
        },
        focus: {
            display: 'none',
        },
        placeholder: {
            color: `rgba(255, 255, 255, 0.4)`,
            whiteSpace: `nowrap`,
            fontSize: `18px`,
            lineHeight: `24px`,
            fontWeight: `300`,
            transform: `translateY(8px)`,
        },
        active: {
            display: 'none',
        },
        error: {
            color: textColors['red'],
        },
        icon: {
            leftPadding: `18px`,
            width: `15px`,
            height: `20px`,
            top: `11px`,
            left: `0`,
        },
    },
};
