import styled, { css } from 'styled-components';
import { baseRow, Game_H2, textColors, baseText } from '../../../../styles';
import { getIconStyles, getInputStyles, getPlaceholderStyles, getWrapperStyles, } from './_helpers';
export const $Input = styled.input `
  z-index: 1;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 0 0px 8px 0px;
  ${Game_H2(textColors['white'])};
  border-radius: 0;

  &:disabled {
    ${Game_H2('rgba(255,255,255, 0.4)')};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 20px transparent inset;
  }

  &[type='date'] {
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      cursor: pointer;
      height: auto;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      outline: none;
    }

    &:not(:focus)[value='']::-webkit-datetime-edit {
      color: transparent;
    }

    &::-webkit-date-and-time-value {
      text-align: left;
    }

    // &::-webkit-datetime-edit {
    //   color: initial;
    // }
  }
`;
export const $Placeholder = styled.span `
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0px;
  transform: translateY(10px);
  transition: 0.1s ease-in-out;
  transform-origin: left;
  display: flex;
  align-items: center;
  user-select: none;
  z-index: 10;

  ${Game_H2(textColors['grey_middle'])}
`;
export const $ErrorText = styled.span `
  ${baseText({ color: textColors['red'] })};
  display: flex;
  align-items: center;
  transition: 0.1s ease-in-out;

  &::before {
    content: '';
    width: 2px;
    height: 2px;
    background-color: ${textColors['red']};
    margin: 0 10px;
  }
`;
export const $Icon = styled.span `
  position: absolute;
  width: 20px;
  height: 20px;
  top: 15px;
  left: 0;
`;
export const $LabelWrapper = styled.p `
  ${Game_H2(textColors['white'])};
`;
export const $LabelText = styled.span ``;
export const $InputWrapper = styled.label `
  position: relative;
  min-height: 50px;
  ${baseRow('flex-start')};
  align-items: flex-end;

  ${({ themeData }) => {
    var _a, _b, _c, _d, _e, _f;
    const wrapperStyles = getWrapperStyles(themeData);
    const { inputStyles, inputWithIconStyles } = getInputStyles(themeData);
    const { placeholderStyles, placeholderWithIconStyles } = getPlaceholderStyles(themeData);
    const iconStyles = getIconStyles(themeData);
    return css `
      ${wrapperStyles}
      overflow: ${(_a = themeData.base) === null || _a === void 0 ? void 0 : _a.overflow};

      ${$Input} {
        ${inputStyles}

        border-bottom: ${themeData.input.borderBottom};

        &:focus {
          border-bottom: ${themeData.focus.borderBottom};

          + ${$Placeholder} {
            transform: ${themeData.focus.transform};
            ${baseText({ color: themeData.focus.color })};
            line-height: ${themeData.focus.lineHeight};
            display: ${themeData.focus.display};
          }
        }

        &:disabled {
          border-bottom: ${(_b = themeData.disabled) === null || _b === void 0 ? void 0 : _b.borderBottom};
        }
      }

      ${$LabelWrapper} {
        ${inputStyles}

        ${$LabelText} {
          opacity: 0.4;
        }

        border-bottom: ${themeData.input.borderBottom};

        & + ${$Input} {
          &:focus {
            border-bottom: ${themeData.input.borderBottom};
          }
        }
      }

      ${$Placeholder} {
        ${placeholderStyles}
        color: ${themeData.placeholder.color};
        white-space: ${themeData.placeholder.whiteSpace};
      }

      &.error {
        ${$Placeholder} {
          color: ${(_c = themeData.error) === null || _c === void 0 ? void 0 : _c.color};
        }

        ${$ErrorText} {
          color: ${(_d = themeData.error) === null || _d === void 0 ? void 0 : _d.color};

          &::before {
            background-color: ${(_e = themeData.error) === null || _e === void 0 ? void 0 : _e.color};
          }
        }

        ${$Input}:focus + ${$Placeholder} ${$ErrorText} {
          display: none;
        }

        ${$Input}:not(:focus) {
          border-color: ${(_f = themeData.error) === null || _f === void 0 ? void 0 : _f.color};
        }
      }

      .active {
        transform: ${themeData.active.transform};
        ${baseText({ color: themeData.active.color })};
        line-height: ${themeData.active.lineHeight};
        display: ${themeData.active.display};
      }

      ${$Icon} {
        ${iconStyles}
      }

      &&.input_which_icon {
        > ${$Input} {
          ${inputWithIconStyles}
        }

        > ${$Placeholder} {
          ${placeholderWithIconStyles}
        }
      }
    `;
}}

  .disabled {
    .input {
      border-bottom: 1px solid gray;
      cursor: default;
    }
    span {
      color: gray;
    }
  }

  &.error {
    &.full {
      ${$Input}:focus {
        & + ${$Placeholder} {
          ${$ErrorText} {
            display: none;
          }
        }
      }
    }

    &.min {
      ${$ErrorText}::before {
        display: none;
      }

      ${$Input}:not(:focus) {
        & + ${$Placeholder} {
          .placeholder_text {
            display: none;
          }
        }
      }

      ${$Input}:focus {
        & + .${$Placeholder} {
          ${$ErrorText} {
            display: none;
          }
        }
      }
    }
  }

  &.input_which_icon {
    ${$Input} {
      padding-left: 30px;
    }
    ${$Placeholder} {
      left: 30px;
    }
  }
`;
