/**
 * Проверяет, является ли переменная объектом.
 * @param {object} obj
 */
export const isObject = (obj) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);
/**
 * Сравнивает два массива.
 * @param {Array<any>} firstArr - первый массив
 * @param {Array<any>} secondArr - второй массив
 * @returns {boolean} equal - Возвращает true, если они равны. False - если не равны.
 */
export const areArraysEqual = (firstArr, secondArr) => {
    if (firstArr.length !== secondArr.length) {
        return false;
    }
    let result = true;
    for (let i = 0; i < firstArr.length; i++) {
        if (!result) {
            break;
        }
        if (Array.isArray(firstArr[i])) {
            if (!Array.isArray(secondArr[i])) {
                result = false;
                break;
            }
            result = areArraysEqual(firstArr[i], secondArr[i]);
            continue;
        }
        if (isObject(firstArr[i])) {
            if (!isObject(secondArr[i])) {
                result = false;
                break;
            }
            result = areObjectsEqual(firstArr[i], secondArr[i]);
            continue;
        }
        if (firstArr[i] !== secondArr[i]) {
            result = false;
        }
    }
    return result;
};
/**
 * Сравнивает объекты.
 * @param {object} firstObject
 * @param {object} secondObject
 * @returns {boolean} equal - Возвращает true, если они равны. False - если не равны.
 */
export const areObjectsEqual = (firstObject, secondObject) => {
    let result = true;
    if (!isObject(firstObject) || !isObject(secondObject)) {
        return false;
    }
    if (Object.entries(firstObject).length !== Object.entries(secondObject).length)
        return false;
    for (const key of Object.keys(firstObject)) {
        if (!result) {
            break;
        }
        if (typeof firstObject[key] !== typeof secondObject[key]) {
            result = false;
            break;
        }
        if (isObject(firstObject[key])) {
            result = areObjectsEqual(firstObject[key], secondObject[key]);
            continue;
        }
        if (Array.isArray(firstObject[key])) {
            if (!Array.isArray(secondObject[key])) {
                result = false;
                break;
            }
            result = areArraysEqual(firstObject[key], secondObject[key]);
            continue;
        }
        if (typeof firstObject[key] === 'function') {
            if (typeof secondObject[key] !== 'function') {
                result = false;
                break;
            }
            result =
                firstObject[key].toString() ===
                    secondObject[key].toString();
            continue;
        }
        if (firstObject[key] !== secondObject[key]) {
            result = false;
        }
    }
    return result;
};
